table {
  background-color: #fff;
  border: 2px solid #42b983;
  border-radius: 3px;
}

th {
  color: #ffffffa8;
  cursor: pointer;
  user-select: none;
  background-color: #42b983;
}

td {
  background-color: #f9f9f9;
}

th, td {
  min-width: 120px;
  padding: 10px 20px;
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  vertical-align: middle;
  width: 0;
  height: 0;
  opacity: .66;
  margin-left: 5px;
  display: inline-block;
}

.arrow.asc {
  border-bottom: 4px solid #fff;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
}

.arrow.dsc {
  border-top: 4px solid #fff;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

/*# sourceMappingURL=index.5ee7f9c7.css.map */
